import React, {useEffect, useState} from 'react';
import { motion } from "framer-motion";
import classes from "./strategy.module.scss";

export type StrategyProps = {
    isActive: boolean,
    isPrevActive: boolean,
    duration: number,
    feature?: boolean,
    small?: boolean
}

export default function Strategy(props: StrategyProps) {
    
    const [animateIn, setAnimateIn] = useState(false);

    useEffect(() => {
        if (props.isActive) {
            const timer = setTimeout(() => {
                setAnimateIn(true);
            }, props.duration * 1000);
            return () => clearTimeout(timer);
        } else if (props.isPrevActive) {
            setAnimateIn(false);
        }
    }, [props.isActive, props.isPrevActive]);

    const variants = {
        visible: { opacity: 1, pathLength: animateIn ? 1 : 0, strokeWidth: animateIn ? 7 : 0 },
        hidden: { opacity: 0, pathLength: animateIn ? 0 : 0 },
    };

    return (
        <div className={classes['icon'] + " " + (props.feature ? classes['feature'] : '') + " " + (props.small? classes['small'] : '')}>
            <svg x="0px" y="0px" viewBox="0 0 290 290">
                <motion.g>
                    {/* polyline to path */}
                    <motion.path
                        d="M265.1,91.5 L257.5,67.9 L233.9,75.5"
                        
                        stroke="#FFFFFF"
                        strokeWidth="0"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        pathLength={0}
                        fill="none"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{
                        duration: ((props.duration)/2),
                        delay: props.duration,
                        }}
                    />

                    {/* line to path */}
                    <motion.path
                        d="M111.7,41.5 L124.3,80.4"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="0"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{
                        duration: (props.duration),
                        delay: props.duration,
                        }}
                    />
                    <motion.path
                        d="M98.5,67.2 L137.5,54.7"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="0"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        initial={variants.hidden}
                        variants={variants}
                        animate={variants.visible}
                        transition={{
                        duration: (props.duration),
                        delay: props.duration,
                        }}
                    />

                    {/* another set of lines to path */}
                    <motion.path
                        d="M195.2,189.5 L207.7,228.4"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="0"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{
                        duration: (props.duration/2),
                        delay: props.duration,
                        }}
                    />
                    <motion.path
                        d="M182,215.2 L220.9,202.7"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="0"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{
                        duration: (props.duration/2),
                        delay: (props.duration/2),
                        }}
                    />

                    {/* the existing path can remain as is */}
                    <motion.path
                        d="M58.7,184l16.2-31.6c10.7-20.9,35.5-30.2,57.3-21.7l39,15.3c21.8,8.6,46.6-0.8,57.3-21.7l26.7-52"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="0"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{
                        delay: (props.duration/3),
                        duration: (props.duration/2),
                        }}
                    />

                    {/* Your circle, and polygon elements can remain as they are, since motion can animate those directly. */}
                    <motion.circle
                        cx="50"
                        cy="205"
                        r="20"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{
                        duration: (props.duration/2),
                        delay: 0,
                        }}
                    />
                </motion.g>
            </svg>
        </div>
    )
}
