import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import classes from "./strategy.module.scss";

export type StrategyProps = {
    isActive: boolean,
    isPrevActive: boolean,
    duration: number,
    feature?: boolean,
    small?: boolean
}

export default function Data(props: StrategyProps) {

    const [animateIn, setAnimateIn] = useState(false);

    useEffect(() => {
        if (props.isActive) {
            // If it&rsquo;s the newly active item, introduce a delay before animating in
            const timer = setTimeout(() => {
                setAnimateIn(true);
            }, 1000); // 1 second delay
            return () => clearTimeout(timer);
        } else if (props.isPrevActive) {
            // If it was the previously active item, animate out immediately
            setAnimateIn(false);
        }
    }, [props.isActive, props.isPrevActive]);

    const variants = {
        visible: { opacity: 1, pathLength: animateIn ? 1 : 0, strokeWidth: animateIn ? 7 : 0 },
        hidden: { opacity: 0, pathLength: animateIn ? 0 : 0 },
    };

    return (
        <div className={classes['icon'] + " " + (props.feature ? classes['feature'] : '') + " " + (props.small? classes['small'] : '')}>
            <motion.svg
                viewBox="0 0 290 290"
                xmlns="http://www.w3.org/2000/svg"
            >
                <motion.g>
                    <motion.line
                        x1="92.1"
                        y1="114.9"
                        x2="39.7"
                        y2="173.7"
                        stroke="#FFFFFF"
                        strokeWidth="7"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="13.7293,13.7293,13.7293,13.7293,13.7293,13.7293"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: props.duration, delay: .2 * props.duration }}
                    />
                    <motion.line
                        x1="245.4"
                        y1="188.8"
                        x2="176.9"
                        y2="187.6"
                        stroke="#FFFFFF"
                        strokeWidth="7"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeDasharray="13.7293,13.7293,13.7293,13.7293,13.7293,13.7293"
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        transition={{ duration: props.duration, delay: .025 * props.duration }}
                    />
                </motion.g>
                <motion.circle
                    cx="28"
                    cy="190"
                    r="20"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{
                        duration: props.duration,
                        delay: 0,
                    }}
                />
                <motion.circle
                    cx="105"
                    cy="96"
                    r="20"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{
                        duration: props.duration,
                        delay: 0.05 * props.duration,
                    }}
                />
                <motion.circle
                    cx="208"
                    cy="96"
                    r="20"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{
                        duration: props.duration,
                        delay: 0.1 * props.duration,
                    }}
                />
                <motion.circle
                    cx="155"
                    cy="190"
                    r="20"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{
                        duration: props.duration,
                        delay: 0.15 * props.duration,
                    }}
                />
                <motion.circle
                    cx="265"
                    cy="190"
                    r="20"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{
                        duration: props.duration,
                        delay: 0.2,
                    }}
                />
            </motion.svg>
        </div>
    )
}
