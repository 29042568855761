import React from 'react';
import cx from 'classnames';
import classes from './home-hero.module.scss';
import BaseCanvas from '../canvas/BaseCanvas';

const Header = () => {  

    const _classes = cx(classes['home-hero'], {});

    return (
        <div className={_classes}>
            <BaseCanvas />
            <h1 className={classes['intro-text']}>Your partner in strategic sports digital marketing.</h1>
            <div className={classes.width}>
            <div className={classes['hero-text']}>eighteen yards</div>
            </div>
        </div>
    )
}

export default Header;

