import React, { useEffect, useMemo } from 'react'
import {Mesh, MeshStandardMaterial,CanvasTexture} from 'three'
import { useGLTF} from "@react-three/drei";
import { GLTF } from "three-stdlib";

export type DreiGLTF = GLTF & {
  nodes: {
    Circle002_1: Mesh;
    Circle002_2: Mesh;
    Plane004_1: Mesh;
    Plane004_2: Mesh;
    Plane005: Mesh;
    Plane005_1: Mesh;
  };
  materials: {
    ["Material.001"]: MeshStandardMaterial;
  };
};


function createGradientTexture(colors: string[], size: number): CanvasTexture {

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Failed to create canvas context');
  }

  canvas.width = size;
  canvas.height = size;

  const gradient = context.createLinearGradient(0, 0, size, size);
  colors.forEach((color, index) => {
    gradient.addColorStop(index / (colors.length - 1), color);
  });

  context.fillStyle = gradient;
  context.fillRect(0, 0, size, size);

  return new CanvasTexture(canvas);
}

export function Model({ onModelLoad, ...props }: { onModelLoad: () => void } & JSX.IntrinsicElements["group"]) {

  
  const { nodes, materials } = useGLTF('/images/logo-collection.glb') as DreiGLTF;
  
  const gradientTexture = useMemo(() => createGradientTexture(['#191919', '#333333', '#d3d3d3'], 1024), []);

  useEffect(() => {
    if (nodes && materials) {
      onModelLoad();
    }
  }, [nodes, materials, onModelLoad]);
  
  useEffect(() => {
    if (materials["Material.001"]) {
      materials["Material.001"].metalness = 0.8;
      materials["Material.001"].roughness = .8;
      materials["Material.001"].needsUpdate = true;
    }
  }, [materials]);
  
  return (
    <group {...props} position={[-0.12,-0.03,0]} rotation={[0.7, 2.8, -0.2]} scale={0.85} dispose={null}>
      <group
        position={[-0.205, -0.087, -0.005]}
        rotation={[1.279, 0.015, 0.07]}
        scale={0.047}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle002_1.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle002_2.geometry}
        >
          <meshStandardMaterial
            flatShading
            map={gradientTexture}
            metalness={0.6}
            roughness={0.3}
            attach="material"

          />
        </mesh>


      </group>
      <group
        position={[0.001, -0.005, -0.015]}
        rotation={[1.279, 0.015, 0.07]}
        scale={0.294}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_1.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane004_2.geometry}
        >
          <meshStandardMaterial
            flatShading
            map={gradientTexture} 
            metalness={0.6}
            roughness={0.3}
            attach="material"

          />
        </mesh>
      </group>
      <group
        position={[-0.045, -0.129, 0.019]}
        rotation={[1.279, 0.015, 0.07]}
        scale={0.125}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane005_1.geometry}
        >
          <meshStandardMaterial
            flatShading
            map={gradientTexture}
            metalness={0.6}
            roughness={0.3}
            attach="material"

          />

        </mesh>

      </group>
    </group>
  );
}




export default Model;
