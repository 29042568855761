import React, { ReactElement, useState } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import classes from './services.module.scss';
import Performance from '@/components/icons/performance';
import Training from '@/components/icons/training';
import Data from '@/components/icons/data';
import Strategy from '@/components/icons/strategy';

export type ServicesProps = {
    children?: ReactElement,
    className?: string,
    background?: { colour: string },
    text?: string,
    type?: 'h2' | 'h3' | 'h4' | 'h5' | 'p',
    column?: number,
    style?: React.CSSProperties,
}

const Text = (props: ServicesProps) => {

    const _classes = cx(classes['wrapper'], {
        [classes[`bg-${props.background?.colour}`]]: props.background?.colour,
    }, props.className);

    const [activeItem, setActiveItem] = useState<number | null>(0);
    const [prevActiveItem, setPrevActiveItem] = useState<number | null>(null);

    const duration = 0.4;

    const servicesList = ['Strategy', 'Performance Marketing', 'Data & Analytics', 'Training'];

    const handleHover = (index: number) => {
        setActiveItem(null);
        setPrevActiveItem(activeItem);
        setActiveItem(index);
    };

    return (
        <section className={_classes}>
            <h2>Our services</h2>
            <div className={classes['split']}>
                <div className={classes.services}>
                    <div className={classes['service-items']}>
                        {servicesList.map((service, index) => (
                            <div
                                key={index}
                                className={`${classes.service} ${activeItem === index ? classes.active : ''}`}
                                onMouseEnter={() => handleHover(index)}
                            >
                                <Link scroll={false} href={index === 0 ? '/strategy' : index === 1 ? '/performance' : index === 2 ? '/data' : index === 3 ? '/training' : ''}>{service}</Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.icons}>
                    <div className={classes['icon']}>
                        <Strategy duration={duration} isActive={activeItem === 0} isPrevActive={prevActiveItem === 0} />
                        <Performance duration={duration} isActive={activeItem === 1} isPrevActive={prevActiveItem === 1} />
                        <Data duration={duration} isActive={activeItem === 2} isPrevActive={prevActiveItem === 2} />
                        <Training duration={duration} isActive={activeItem === 3} isPrevActive={prevActiveItem === 3} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Text;