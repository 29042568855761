import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import classes from "./strategy.module.scss";

export type StrategyProps = {
    isActive: boolean,
    isPrevActive: boolean,
    duration: number,
    feature?: boolean,
    small?: boolean
}

export default function Training(props: StrategyProps) {

    const [loaded, setLoaded] = useState(false);
    const [animateIn, setAnimateIn] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (props.isActive) {
            // If it&rsquo;s the newly active item, introduce a delay before animating in
            const timer = setTimeout(() => {
                setAnimateIn(true);
            }, 1000); // 1 second delay
            return () => clearTimeout(timer);
        } else if (props.isPrevActive) {
            // If it was the previously active item, animate out immediately
            setAnimateIn(false);
        }
    }, [props.isActive, props.isPrevActive]);

    const variants = {
        visible: { opacity: 1, pathLength: animateIn ? 1 : 0, strokeWidth: animateIn ? 7 : 0 },
        hidden: { opacity: 0, pathLength: animateIn ? 0 : 0 },
    };

    return (
        <div className={classes['icon'] + " " + (props.feature ? classes['feature'] : '') + " " + (props.small? classes['small'] : '')}>
            <motion.svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 290 290"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <motion.g>
                    <motion.g>
                        <motion.path
                            d="M33.8,125.2c-0.5,0-1.1,0.1-1.6,0.2c7.9-47.8,45.5-85.6,93.2-93.8c0,0.1,0,0.2-0.1,0.4
            c-0.1,0.6-0.1,1.3-0.1,1.9C79.5,42,42.2,77.8,33.8,125.2z"
                            stroke="#FFFFFF"
                            fill="none"
                            strokeWidth="5"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeDasharray="13.7293,13.7293,13.7293,13.7293,13.7293,13.7293"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * .3 }}
                        />
                        <motion.path
                            d="M125.2,256.1c0,0.3,0,0.5,0,0.8c-47.1-8.2-84.3-45.3-92.8-92.3c0.2,0,0.4,0.1,0.6,0.1c0.3,0,0.5,0.1,0.8,0.1
            C41.9,210.4,77.7,247.8,125.2,256.1z"
                            stroke="#FFFFFF"
                            fill="none"
                            strokeWidth="5"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeDasharray="13.7293,13.7293,13.7293,13.7293,13.7293,13.7293"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * .25 }}
                        />
                        <motion.path
                            d="M257.6,164.6c-8.4,47.1-45.7,84.1-92.8,92.3c0-0.3,0-0.5,0-0.8c45.6-8.1,82.9-44,91.3-91.4
            C256.6,164.8,257.1,164.7,257.6,164.6z"
                            stroke="#FFFFFF"
                            fill="none"
                            strokeWidth="5"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            strokeDasharray="13.7293,13.7293,13.7293,13.7293,13.7293,13.7293"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * 0.15 }}
                        />
                        <motion.path
                            d="M164.8,33.9c0-0.8-0.1-1.5-0.2-2.3c47.7,8.2,85.3,46,93.2,93.8c-0.3,0-0.6-0.1-0.8-0.1
            c-0.3,0-0.6-0.1-0.9-0.1C248,79.6,212.2,42.3,164.8,33.9z"
                            stroke="#FFFFFF"
                            fill="none"
                            strokeWidth="5"
                            strokeLinecap="round"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * 0.05 }}
                        />
                    </motion.g>
                    <motion.g>
                        <motion.circle cx="145" cy="34.3" r="20" fill="none" stroke="#FFFFFF"
                        
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * 0 }} />
                    </motion.g>
                    <motion.g>
                        <motion.circle cx="254.6" cy="145" r="20" fill="none" stroke="#FFFFFF"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * .2 }} />
                    </motion.g>
                    <motion.g>
                        <motion.circle
                            cx="145" cy="257" r="20"
                            fill="none"
                            stroke="#FFFFFF"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * .3 }}
                        />
                    <motion.g>
                        <motion.circle
                            cx="30"
                            cy="145"
                            r="20"
                            fill="none"
                            stroke="#FFFFFF"
                            strokeWidth="7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            initial="hidden"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: props.duration, delay: props.duration * .1 }}
                        />
                    </motion.g>
                    </motion.g>
                </motion.g>
            </motion.svg>
        </div>
    )
}
