import React, { useEffect, useState, useRef } from 'react';
import { Vector3 } from 'three';
import {Environment, Float } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import GLBCanvas from '@/components/canvas/GLBCanvas';
import Lights from '@/components/canvas/Lights';
import classes from './canvas.module.scss';

export default function BaseCanvas() {

    const [canvasSize, setCanvasSize] = useState({ height: 1080, cameraPosition: [0, 0, -0.2] });

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleModelLoad = () => {
        if (wrapperRef.current) {
            wrapperRef.current.classList.add(classes['loaded']);
        }
    };

    function debounce<F extends (...args: any[]) => void>(func: F, wait: number): (...args: Parameters<F>) => void {
        let timeout: NodeJS.Timeout | null = null;
    
        return function(...args: Parameters<F>): void {
            const later = () => {
                timeout = null;
                func(...args);
            };
    
            if (timeout !== null) {
                clearTimeout(timeout);
            }
            
            timeout = setTimeout(later, wait);
        };
    }

    useEffect(() => {
        // Define the handleResize function inside useEffect
        const handleResize = () => {
            const width = window.innerWidth;

            if (width <= 766) {
                setCanvasSize({ height: 400, cameraPosition: [.45, -0.1, -0.8] });
            } else if (width <= 1024) {
                setCanvasSize({ height: 700, cameraPosition: [0, 0, -0.4] });
            } else {
                setCanvasSize({ height: 1080, cameraPosition: [0, 0, -0.2] });
            }
        };

        // Debounced version of the resize handler
        const debouncedHandleResize = debounce(handleResize, 300);

        window.addEventListener('resize', debouncedHandleResize);

        // Call the resize handler once on component mount to set initial size
        handleResize();

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    return (
        <>
        <div ref={wrapperRef} className={classes['wrapper']}>
            <Canvas shadows
                style={{ height: canvasSize.height, zIndex: 1 }}
                className={classes['canvas']}
                camera={{ position: new Vector3(...canvasSize.cameraPosition), fov: 26 }}>
                <Lights />
                <Float
                    speed={1.5} // Animation speed, defaults to 1
                    position={[-0.1, 0.1, 0.4]}
                    rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
                    floatIntensity={0} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                    floatingRange={[0, 0.7]}
                >
                    <GLBCanvas onModelLoad={handleModelLoad} receiveShadow />
                </Float>
                <Environment frames={60} files={['/hdri/px.png', '/hdri/nx.png', '/hdri/py.png', '/hdri/ny.png', '/hdri/pz.png', '/hdri/nz.png']} resolution={256} background={false} blur={0.8}>
                </Environment>
            </Canvas>
        </div>
        <div className={classes['image-wrapper']}>
            <img className={classes['mobile-image']}  src="./images/logo.webp" alt="eighteenyards logo" />

        </div>
        </>
    )
}