import Head from 'next/head';
import {DefaultSeo} from 'next-seo';

export default function StructuredData() {
  return (
    <>
      <DefaultSeo
      // Define default SEO configuration here
      />
      <Head>
        <title>
          eighteen yards | sports digital marketing agency
        </title>
        <meta
          name="description"
          content="Get ahead with our data-driven sports digital marketing. Increase revenue, grow your brand, engage your fanbase. Learn more about eighteen yards."
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "eighteen yards",
              "url": "https://eighteenyards.com",
              "logo": "https://eighteenyards.com/logo.png",
              "description": "Get ahead with our data-driven sports digital marketing. Increase revenue, grow your brand, engage your fanbase.",
              "image": "https://eighteenyards.com/images/bg.jpg",
              "sameAs": [
                "https://www.facebook.com/eighteenyards",
                "https://twitter.com/eighteenyards",
                "https://www.linkedin.com/company/eighteen-yards",
                "https://www.instagram.com/eighteenyards"
              ],
              "serviceArea": {
                "@type": "AdministrativeArea",
                "name": "Australia"
              },
              "service": [
                {
                  "@type": "Service",
                  "name": "Sports Digital Marketing"
                },
                {
                  "@type": "Service",
                  "name": "Performance Marketing Strategy"
                },
                {
                  "@type": "Service",
                  "name": "Digital Marketing Strategy"
                },
                {
                  "@type": "Service",
                  "name": "Search Engine Optimisation"
                },
                {
                  "@type": "Service",
                  "name": "Content Marketing"
                },
                {
                  "@type": "Service",
                  "name": "Social Media Advertising"
                },
                {
                  "@type": "Service",
                  "name": "Google Advertising"
                },
                {
                  "@type": "Service",
                  "name": "Google Tag Manager"
                },
                {
                  "@type": "Service",
                  "name": "Google Analytics"
                },
                {
                  "@type": "Service",
                  "name": "Google Looker Studio"
                },
                {
                  "@type": "Service",
                  "name": "Adobe Analytics"
                },
                {
                  "@type": "Service",
                  "name": "Digital Marketing Training"
                }
              ],
            }),
          }}
        />
      </Head>
    </>
  )
}
