'use client';

export default function Lights() {

  return (
    <>
      <ambientLight intensity={0.2} />
      <pointLight position={[10, 2, -2]} intensity={0.3} />

      <hemisphereLight intensity={5} color="#ffffff" groundColor="black" />
      <spotLight 
        castShadow 
        color="white" 
        intensity={.3} 
        position={[0, 1, 1]} 
        angle={.35} 
        penumbra={1} 
        shadow-mapSize={[1024, 1024]} 
        shadow-bias={0.0001} 
      />

      <spotLight 
        castShadow 
        color="white" 
        intensity={.5} 
        position={[0, 0, -2]} 
        angle={.35} 
        penumbra={1} 
        shadow-mapSize={[1024, 1024]} 
        shadow-bias={0.0001} 
      />

      <directionalLight  
        position={[-3, 6, 4]} 
        intensity={.5} 
        shadow-mapSize={[1024, 1024]} 
        shadow-bias={0.0001} 
      />

      <pointLight  position={[0, 1, -1]} intensity={2} />
    </>
  )
}
