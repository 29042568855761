import Image from 'next/image'
import classes from './home.module.scss';
import PageTransition from '@/components/page-transition/PageTransition';
import Text from '@/components/text/text';
import Services from '@/components/services/services';
import HomeHero from '@/components/hero/home-hero';
import Button from '@/components/button/button';
import Soccer from '@/assets/images/soccer.webp';
import StructuredData from './structured-data';
import Link from 'next/link';
import Contact from '@/components/contact/contact';
import ContentArea from '@/components/content-area/content-area';

export default function Home() {

  return (
    <>
      <StructuredData />
      <PageTransition>
        <div className={'body-bg'}></div>
        <main className={classes.wrapper}>
          <HomeHero />

          <ContentArea>
            <>
              <Text type={'h2'} column={1} size='medium' >Focused on the <span style={{display: 'block'}}>play-by-play</span></Text>
              <Text size={"medium"} type={'h3'} column={2} as={"h5"} >Understand the strategies and tactics that will take your business to the next level.</Text>
              <Text size={'medium'} type={'p'} column={2} >Increase your revenue, grow your brand&rsquo;s reach, and engage with your fanbase more effectively.</Text>
              <Text size={'medium'} type={'p'} column={2} >Whatever your goals, we use our expertise in data-driven <Link scroll={false} href="/performance">performance marketing</Link> to help you dominate your competition.</Text>
              <Button mobile_right link="/purpose" title="view our purpose" style="primary" column={3} />
              <Image className={classes['content-image']} alt="soccer" src={Soccer} width="600" height="600" />
            </>
          </ContentArea>

          <Services />

          <Contact />
        </main>
      </PageTransition>
    </>
  )
}
